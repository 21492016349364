/*
prompt:
page title: image generator
page description: generate images for your flashcards
page logic:
* the page has three parts: 

** the image part: show image, refresh every second (the top part)

** the input part: user can input text message and click submit button(the middle part)

** the input history part: show the input history(the bottom part)

using reactjs to implement the page logic
using meterial-ui to implement the page layout
as beautifull as possible

page style: this page is like a chatgroup, the user type message in the group, the bot give the response

page logic:
* user define prompt in text box
* when user click generate, generate image, and then call remote api, the api will return the image url
* display the image url in the page(refresh the image every second)
* when the image is ready, user can select from 1 to 4 candidate images to scale to large size
* when user select the number and click submit, then will call another remote api to scale up the image
* the api will return the image url
* user can download the image

constrains:
* before generate button clicked, no picture shows; 
* after generate button clicked,  the select option shows,the picture shows and refresh every second
* when image option selected, the scale button shows
* when click the scale button, the image shows and the download button shows



page logic:
* user can type text message
* when user click send, the message will be sent to the bot
* the bot will response the message, for example, the bot will display the message in the chatgroup


*/
import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Typography, Paper } from '@material-ui/core';

const ImageGenerator = () => {
    const [message, setMessage] = useState('');
    const [chat, setChat] = useState([]);
    const [imageUrl, setImageUrl] = useState('');

    const handleInputChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSendClick = () => {
        // Call remote API to send message to bot
        // Add the message to chat state
        setChat([...chat, { sender: 'user', message }]);
        setMessage('');
    };

    useEffect(() => {
        const interval = setInterval(() => {
            // Refresh the image every second
            setImageUrl('https://media.discordapp.net/attachments/1139852256838172732/1140196635247382549/mjl8789_7353646020Surprised_the_proud_Chinese_children_cleverly_f2a7aa72-5a7b-4052-a2f3-a6581d81786d.png');
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h4">Image Generator</Typography>
                <Typography variant="subtitle1">Generate images for your flashcards</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper style={{ height: '600px', overflow: 'auto', padding: '10px' }}>
                    <img src={imageUrl} alt="placeholder" style={{ width: '100%', height: 'auto' }} />
                </Paper>
            </Grid>
            
            <Grid item xs={12}>
                <TextField
                    label="Type your message here"
                    variant="outlined"
                    multiline
                    rows={4}
                    fullWidth
                    value={message}
                    onChange={handleInputChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleSendClick}>
                    Submit
                </Button>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper style={{ height: '400px', overflow: 'auto', padding: '10px' }}>
                    {chat.map((item, index) => (
                        <div key={index}>
                            <Typography variant="subtitle2" style={{ color: 'green' }}>{item.sender} ({new Date().toLocaleString()})</Typography>
                            <Typography variant="body1">{item.message}</Typography>
                        </div>
                    ))}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default ImageGenerator;